const cssString = `
@import url('/fonts/VprInter/stylesheet.css');
body {
    font-family: 'Roboto', 'VprInter', sans-serif;
}
.mceNonEditable > * {
    pointer-events: none;
}
.d-inlide-block {
    display: inline-block;
}
.subtask_illustrations {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
}
.subtask_image {
    display: flex;
    align-items: center;
    flex-direction: column;
}
`
export default cssString