<template>
    <form-view 
        v-slot="{ model, onSuccess, onBack }"
        title="Редактирование подсказки"
        update
        url-update="/hint/update/:id"
        url-back="/hint"
        action-one="hint/one"
    >
        <hint-form type="job-hint" :model="model" @success="onSuccess" @back="onBack" />
    </form-view>
</template>
<script>
import FormView from '@/components/crud/FormView.vue'
import HintForm from '@/components/forms/HintForm.vue'
export default {
    name: 'HintUpdate',
    components: { FormView, HintForm }
}
</script>