export default function (editor) {
    /* Adds a menu item, which can then be included in any menu via the menu/menubar configuration */
    editor.ui.registry.addMenuItem('audioRecordShortcode', {
        text: 'Запись голоса',
        onAction: function () {
            editor.insertContent(`<p class="mceNonEditable">@@shortcode-audio-record id="${editor.getMaxIdValue(editor.dom.doc.body.innerHTML) + 1}"@@</p>`);
        }
    });
    /* Return the metadata for the help plugin */
    return {
        getMetadata: function () {
            return  {
                name: 'Шорткод: Запись голоса',
                url: '#'
            };
        }
    };
}