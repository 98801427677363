<template>
    <v-form>
        <v-alert
          v-if="!_.isNil(summaryError)"
          dense
          type="error"
        >
            {{ summaryError }}
        </v-alert>

        <v-text-field 
            v-model="form.name"
            :error-messages="getErrors('form.name')"
            label="Название"
            name="name"
            type="text" 
            :color="$const.color.primary" 
        ></v-text-field>

        <wysiwyg-editor
            :value="form.content"
            @input="onWysiwygInput"
            class="mb-5"
        />

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />
    </v-form>
</template>
<script>

import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import WysiwygEditor from '@/components/inputs/wysiwyg-editor/index.vue'


export default {
    components: { WysiwygEditor, ActionButtons },
    props: {
        model: { type: Object },
        type: { type: String, required: true }
    },
    provide () {
        return {
            model: this.model
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            storeModule: 'hint',
            form: {
                name: '',
                content: '',
                type: null
            }
        };
    },
    validations() {
        return {
            form: {
                name: { required },
                content: {}
            }
        }
    },
    created () {
        this.form.type = this.type;
    },
    methods: {
        onValidationFailed () {
            window.scrollTo(0, 0)
        },
        onWysiwygInput (html) {
            if (typeof html === 'string') {
                this.form.content = html;
            }
        },
    }
}
</script>