export default `
<div>
    <p>
        Вкладка верхнего меню "Шорткоды" вызывает выпадающий список доступных к использованию шорткодов.
        По клику на любой элемент списка на место курсора будет вставлен в контент вида '@@shortcode-name@@'.
        Данный код неизменяемый - его нельзя отредактировать. Только удалить или перетащить на другое место.
    </p>
    <p>
        В приложении эти блоки кода будут автоматически заменены на соответствующие интерактивные поля.
    </p>
    <p>
        Добавление шорткода в редактор вёрстки автоматически создает блок его настроек ниже редактора.
    </p>
</div>
`